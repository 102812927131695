import i18n from 'i18next';
import i18nBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

const options = {
  order: ['path','cookie','navigator'],
  caches: ['cookie'],
}

i18n
  .use(i18nBackend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
      detection: options,
      load: 'languageOnly',
      fallbackLng: "es",
      lang: "es",
      supportedLngs: ['es', 'en', 'ca'],
      interpolation: {
          escapeValue: false,
    },
    backend: {
          loadPath : `${window.location.origin}/i18n/{{lng}}.json`,
    }

  });

export default i18n;
