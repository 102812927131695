import React, {lazy, Suspense} from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "i18n";
import "assets/css/a_font-awesome-5.9.0.css";
import "assets/css/b_bootstrap.min.css";
import "assets/css/c_magnific-popup.css";
import "assets/css/d_flaticon.css";
import "assets/css/e_slick.css";
import "assets/css/main.css";
import "assets/css/style.css";
import reportWebVitals from "./reportWebVitals";
import Loading from "./components/helpers/Loading";

const App = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve ( import("./App"),),1500);
  });
});


ReactDOM.render(
  <Suspense fallback={<Loading />}>
    <BrowserRouter>
        <App />
    </BrowserRouter>
  </Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
